import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ControllerService } from '../../service/controller.service';

@Component({
  selector: 'app-trainer',
  templateUrl: './trainer.component.html',
  styleUrls: ['./trainer.component.scss']
})
export class TrainerComponent implements OnInit {

  constructor(
    public controller: ControllerService,
    private title: Title,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.translate.get("titles.trainer").subscribe(title => this.title.setTitle(title));
  }

}
