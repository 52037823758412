<main>
  <div>
    <h2>Zugriffsdaten und Hosting</h2>
    <p>
      Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu
      machen. Bei jedem Aufruf einer Webseite speichert der Webserver lediglich
      automatisch ein sogenanntes Server-Logfile, das z.B. den Namen der
      angeforderten Datei, Ihre IP-Adresse, Datum und Uhrzeit des Abrufs,
      übertragene Datenmenge und den anfragenden Provider (Zugriffsdaten)
      enthält und den Abruf dokumentiert.
    </p>
    <p>
      Diese Zugriffsdaten werden ausschließlich zum Zwecke der Sicherstellung
      eines störungsfreien Betriebs der Seite sowie der Verbesserung unseres
      Angebots ausgewertet. Dies dient gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO der
      Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden
      berechtigten Interessen an einer korrekten Darstellung unseres Angebots.
      Alle Zugriffsdaten werden spätestens sieben Tage nach Ende Ihres
      Seitenbesuchs gelöscht.
    </p>
    <p>
      Hostingdienstleistungen durch einen Drittanbieter Im Rahmen einer
      Verarbeitung in unserem Auftrag erbringt ein Drittanbieter für uns die
      Dienste zum Hosting und zur Darstellung der Webseite. Alle Daten, die im
      Rahmen der Nutzung dieser Webseite oder in dafür vorgesehenen Formularen
      im Onlineshop wie folgend beschrieben erhoben werden, werden auf seinen
      Servern verarbeitet. Eine Verarbeitung auf anderen Servern findet nur in
      dem hier erläuterten Rahmen statt.
    </p>
    <p>
      Dieser Dienstleister sitzt in den USA und ist zertifiziert unter dem
      EU-US-Privacy Shield. Ein aktuelles Zertifikat kann
      <a href="https://www.privacyshield.gov/list" target="_blank">hier</a>
      eingesehen werden. Aufgrund dieses Abkommens zwischen den USA und der
      Europäischen Kommission hat letztere für unter dem Privacy Shield
      zertifizierte Unternehmen ein angemessenes Datenschutzniveau festgestellt.
    </p>
  </div>
  <div>
    <h2>
      Datenerhebung und -verwendung zur Vertragsabwicklung, Kontaktaufnahme und
      bei Eröffnung eines Kundenkontos
    </h2>
    <p>
      Wir erheben personenbezogene Daten, wenn Sie uns diese im Rahmen Ihrer
      Bestellung oder bei einer Kontaktaufnahme mit uns (z.B. per
      Kontaktformular oder E-Mail) freiwillig mitteilen. Pflichtfelder werden
      als solche gekennzeichnet, da wir in diesen Fällen die Daten zwingend zur
      Vertragsabwicklung, bzw. zur Bearbeitung Ihrer Kontaktaufnahme benötigen
      und Sie ohne deren Angabe die Bestellung bzw. die Kontaktaufnahme nicht
      versenden können. Welche Daten erhoben werden, ist aus den jeweiligen
      Eingabeformularen ersichtlich. Wir verwenden die von ihnen mitgeteilten
      Daten gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO zur Vertragsabwicklung und
      Bearbeitung Ihrer Anfragen. Soweit Sie hierzu Ihre Einwilligung nach Art.
      6 Abs. 1 S. 1 lit. a DSGVO erteilt haben, indem Sie Sich für die Eröffnung
      eines Kundenkontos entscheiden, verwenden wir Ihre Daten zum Zwecke der
      Kundenkontoeröffnung. Nach vollständiger Abwicklung des Vertrages oder
      Löschung Ihres Kundenkontos werden Ihre Daten für die weitere Verarbeitung
      eingeschränkt und nach Ablauf der steuer- und handelsrechtlichen
      Aufbewahrungsfristen gelöscht, sofern Sie nicht ausdrücklich in eine
      weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns eine darüber
      hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und
      über die wir Sie in dieser Erklärung informieren. Die Löschung Ihres
      Kundenkontos ist jederzeit möglich und kann entweder durch eine Nachricht
      an die unten beschriebene Kontaktmöglichkeit oder über eine dafür
      vorgesehene Funktion im Kundenkonto erfolgen.
    </p>
  </div>
  <div>
    <h2>Datenweitergabe</h2>
    <p>
      Zur Vertragserfüllung gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO geben wir Ihre
      Daten an das mit der Lieferung beauftragte Versandunternehmen weiter,
      soweit dies zur Lieferung bestellter Waren erforderlich ist. Je nach dem,
      welchen Zahlungsdienstleister Sie im Bestellprozess auswählen, geben wir
      zur Abwicklung von Zahlungen die hierfür erhobenen Zahlungsdaten an das
      mit der Zahlung beauftragte Kreditinstitut und ggf. von uns beauftragte
      Zahlungsdienstleister weiter bzw. an den ausgewählten Zahlungsdienst. Zum
      Teil erheben die ausgewählten Zahlungsdienstleister diese Daten auch
      selbst, soweit Sie dort ein Konto anlegen. In diesem Fall müssen Sie sich
      im Bestellprozess mit Ihren Zugangsdaten bei dem Zahlungsdienstleister
      anmelden. Es gilt insoweit die Datenschutzerklärung des jeweiligen
      Zahlungsdienstleisters.
    </p>
  </div>
  <div>
    <h2>Integration des Trusted Shops Trustbadge</h2>
    <p>
      Zur Anzeige unseres Trusted Shops Gütesiegels und der gegebenenfalls
      gesammelten Bewertungen sowie zum Angebot der Trusted Shops Produkte für
      Käufer nach einer Bestellung ist auf dieser Webseite das Trusted Shops
      Trustbadge eingebunden.
    </p>
    <p>
      Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung
      überwiegenden berechtigten Interessen an einer optimalen Vermarktung durch
      Ermöglichung eines sicheren Einkaufs gemäß Art. 6 Abs. 1 S. 1 lit. f
      DSGVO. Das Trustbadge und die damit beworbenen Dienste sind ein Angebot
      der Trusted Shops GmbH, Subbelrather Str. 15C, 50823 Köln. Das Trustbadge
      wird im Rahmen einer Auftragsverarbeitung durch einen CDN-Anbieter
      (Content-Delivery-Network) zur Verfügung gestellt. Die Trusted Shops GmbH
      setzt auch Dienstleister aus den USA ein. Ein angemessenes
      Datenschutzniveau ist sichergestellt. Weitere Informationen zum
      Datenschutz der Trusted Shops GmbH finden Sie
      <a
        href="https://www.trustedshops.de/impressum/#datenschutz"
        target="_blank"
        >hier</a
      >.
    </p>
    <p>
      Bei dem Aufruf des Trustbadge speichert der Webserver automatisch ein
      sogenanntes Server-Logfile, das auch Ihre IP-Adresse, Datum und Uhrzeit
      des Abrufs, übertragene Datenmenge und den anfragenden Provider
      (Zugriffsdaten) enthält und den Abruf dokumentiert. Einzelne Zugriffsdaten
      werden für die Analyse von Sicherheitsauffälligkeiten in einer
      Sicherheitsdatenbank gespeichert. Die Logfiles werden spätestens 90 Tage
      nach Erstellung automatisch gelöscht.
    </p>
    <p>
      Weitere personenbezogene Daten werden an die Trusted Shops GmbH
      übertragen, wenn Sie sich nach Abschluss einer Bestellung für die Nutzung
      von Trusted Shops Produkten entscheiden oder sich bereits für die Nutzung
      registriert haben. Es gilt die zwischen Ihnen und Trusted Shops getroffene
      vertragliche Vereinbarung. Hierfür erfolgt eine automatische Erhebung
      personenbezogener Daten aus den Bestelldaten. Ob Sie als Käufer bereits
      für eine Produktnutzung registriert ist, wird anhand eines neutralen
      Parameters, der per kryptologischer Einwegfunktion gehashten
      E-Mail-Adresse, automatisiert überprüft. Die E-Mail Adresse wird vor der
      Übermittlung in diesen für Trusted Shops nicht zu entschlüsselnden
      Hash-Wert umgerechnet. Nach Überprüfung auf einen Match, wird der
      Parameter automatisch gelöscht.
    </p>
    <p>
      Dies ist für die Erfüllung unserer und Trusted Shops‘ überwiegender
      berechtigter Interessen an der Erbringung des jeweils an die konkrete
      Bestellung gekoppelten Käuferschutzes und der transaktionellen
      Bewertungsleistungen gem. Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich.
      Weitere Details, auch zum Widerspruch, sind der oben und im Trustbadge
      verlinkten Trusted Shops Datenschutzerklärung zu entnehmen.
    </p>
  </div>
  <div>
    <h2>Cookies</h2>
    <p>
      Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung
      bestimmter Funktionen zu ermöglichen, um passende Produkte anzuzeigen oder
      zur Marktforschung verwenden wir auf verschiedenen Seiten sogenannte
      Cookies. Dies dient der Wahrung unserer im Rahmen einer
      Interessensabwägung überwiegenden berechtigten Interessen an einer
      optimierten Darstellung unseres Angebots gemäß Art. 6 Abs. 1 S. 1 lit. f
      DSGVO. Cookies sind kleine Textdateien, die automatisch auf Ihrem Endgerät
      gespeichert werden. Einige der von uns verwendeten Cookies werden nach
      Ende der Browser-Sitzung, also nach Schließen Ihres Browsers, wieder
      gelöscht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem
      Endgerät und ermöglichen uns, Ihren Browser beim nächsten Besuch
      wiederzuerkennen (persistente Cookies). Die Dauer der Speicherung können
      Sie der Übersicht in den Cookie-Einstellungen Ihres Webbrowsers entnehmen.
      Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
      Cookies informiert werden und einzeln über deren Annahme entscheiden oder
      die Annahme von Cookies für bestimmte Fälle oder generell ausschließen.
      Jeder Browser unterscheidet sich in der Art, wie er die
      Cookie-Einstellungen verwaltet. Diese ist in dem Hilfemenü jedes Browsers
      beschrieben, welches Ihnen erläutert, wie Sie Ihre Cookie-Einstellungen
      ändern können. Diese finden Sie für die jeweiligen Browser unter den
      folgenden Links:
    </p>

    <ul>
      <li>
        <a
          href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies"
          target="_blank"
          >Internet Explorer™</a
        >
      </li>
      <li>
        <a
          href="https://support.apple.com/de-de/guide/safari/sfri11471/12.0/mac/10.14"
          target="_blank"
          >Safari™</a
        >
      </li>
      <li>
        <a
          href="https://support.google.com/chrome/answer/95647?hl=de&hlrm=en"
          target="_blank"
          >Chrome™</a
        >
      </li>
      <li>
        <a
          href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen"
          target="_blank"
          >Firefox™</a
        >
      </li>
      <li>
        <a
          href="https://help.opera.com/de/latest/web-preferences/#cookies"
          target="_blank"
          >Opera™</a
        >
      </li>
    </ul>
    Bei der Nichtannahme von Cookies kann die Funktionalität unserer Website
    eingeschränkt sein.
    <p></p>
  </div>

  <div>
    <h2>Online-Marketing</h2>
    <p>
      Google Fonts Auf dieser Webseite ist der Script Code „Google Fonts“
      eingebunden. Google Fonts ist ein Angebot der Google Ireland Limited,
      einer nach irischem Recht eingetragenen und betriebenen Gesellschaft mit
      Sitz in Gordon House, Barrow Street, Dublin 4, Irland. (<a
        href="www.google.de"
        target="_blank"
        >www.google.de</a
      >). Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung
      überwiegenden berechtigten Interessen an einer einheitlichen Darstellung
      der Inhalte auf unserer Webseite gemäß Art. 6 Abs. 1 lit. f) DSGVO. In
      diesem Rahmen wird eine Verbindung zwischen dem von Ihnen verwendeten
      Browser und den Servern von Google hergestellt. Hierdurch erlangt Google
      Kenntnis darüber, dass über Ihre IP-Adresse unsere Webseite aufgerufen
      wurde. Soweit Informationen auf Server von Google in den USA übertragen
      und dort gespeichert werden, ist die amerikanische Gesellschaft Google LLC
      unter dem EU-US-Privacy Shield zertifiziert. Ein aktuelles Zertifikat kann
      <a href="https://www.privacyshield.gov/list" target="_blank">hier</a>
      eingesehen werden. Aufgrund dieses Abkommens zwischen den USA und der
      Europäischen Kommission hat letztere für unter dem Privacy Shield
      zertifizierte Unternehmen ein angemessenes Datenschutzniveau festgestellt.
      Weitergehende Informationen über die Datenverarbeitung durch Google finden
      Sie in den
      <a href="https://policies.google.com/privacy" target="_blank"
        >Datenschutzhinweisen</a
      >
      von Google.
    </p>
    <p>
      Adobe Typekit Auf dieser Webseite ist der Script Code „Adobe Typekit“ des
      Unternehmens Adobe Systems Incorporated 345 Park Avenue San Jose, CA
      95110-2704, USA (nachfolgend: Adobe) eingebunden. Dies dient der Wahrung
      unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten
      Interessen an einer einheitlichen Darstellung der Inhalte auf unserer
      Webseite gemäß Art. 6 Abs. 1 lit. f) DSGVO. In diesem Rahmen wird eine
      Verbindung zwischen dem von Ihnen verwendeten Browser und den Servern von
      Adobe hergestellt. Hierdurch erlangt Adobe Kenntnis darüber, dass über
      Ihre IP-Adresse unsere Webseite aufgerufen wurde. Adobe ist zertifiziert
      unter dem EU-US-Privacy Shield. Ein aktuelles Zertifikat kann
      <a href="https://www.privacyshield.gov/list" target="_blank">hier</a>
      eingesehen werden. Aufgrund dieses Abkommens zwischen den USA und der
      Europäischen Kommission hat letztere für unter dem Privacy Shield
      zertifizierte Unternehmen ein angemessenes Datenschutzniveau festgestellt.
      Weitergehende Informationen über die Datenverarbeitung im Rahmen von Adobe
      Typekit finden Sie in den
      <a
        href="https://www.adobe.com/de/privacy/policies/typekit.html"
        target="_blank"
        >Datenschutzhinweisen</a
      >
      von Adobe.
    </p>
  </div>
  <div>
    <h2>Kontaktmöglichkeiten und Ihre Rechte</h2>
    <p>
      Als Betroffener haben Sie folgende Rechte: * gemäß Art. 15 DSGVO das
      Recht, in dem dort bezeichneten Umfang Auskunft über Ihre von uns
      verarbeiteten personenbezogenen Daten zu verlangen; * gemäß Art. 16 DSGVO
      das Recht, unverzüglich die Berichtigung unrichtiger oder
      Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu
      verlangen; * gemäß Art. 17 DSGVO das Recht, die Löschung Ihrer bei uns
      gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die
      weitere Verarbeitung * zur Ausübung des Rechts auf freie Meinungsäußerung
      und Information; * zur Erfüllung einer rechtlichen Verpflichtung; * aus
      Gründen des öffentlichen Interesses oder * zur Geltendmachung, Ausübung
      oder Verteidigung von Rechtsansprüchen erforderlich ist; * gemäß Art. 18
      DSGVO das Recht, die Einschränkung der Verarbeitung Ihrer
      personenbezogenen Daten zu verlangen, soweit * die Richtigkeit der Daten
      von Ihnen bestritten wird; * die Verarbeitung unrechtmäßig ist, Sie aber
      deren Löschung ablehnen; * wir die Daten nicht mehr benötigen, Sie diese
      jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
      benötigen oder * Sie gemäß Art. 21 DSGVO Widerspruch gegen die
      Verarbeitung eingelegt haben; * gemäß Art. 20 DSGVO das Recht, Ihre
      personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem
      strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder
      die Übermittlung an einen anderen Verantwortlichen zu verlangen; * gemäß
      Art. 77 DSGVO das Recht, sich bei einer Aufsichtsbehörde zu beschweren. In
      der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen
      Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes
      wenden.
    </p>
    <p>
      Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen
      Daten, bei Auskünften, Berichtigung, Einschränkung oder Löschung von Daten
      sowie Widerruf erteilter Einwilligungen oder Widerspruch gegen eine
      bestimmte Datenverwendung wenden Sie sich bitte direkt an uns über die
      Kontaktdaten in unserem Impressum.
    </p>
    <p>
      Widerspruchsrecht Soweit wir zur Wahrung unserer im Rahmen einer
      Interessensabwägung überwiegenden berechtigten Interessen personenbezogene
      Daten wie oben erläutert verarbeiten, können Sie dieser Verarbeitung mit
      Wirkung für die Zukunft widersprechen. Erfolgt die Verarbeitung zu Zwecken
      des Direktmarketings, können Sie dieses Recht jederzeit wie oben
      beschrieben ausüben. Soweit die Verarbeitung zu anderen Zwecken erfolgt,
      steht Ihnen ein Widerspruchsrecht nur bei Vorliegen von Gründen, die sich
      aus Ihrer besonderen Situation ergeben, zu.
    </p>
    <p>
      Nach Ausübung Ihres Widerspruchsrechts werden wir Ihre personenbezogenen
      Daten nicht weiter zu diesen Zwecken verarbeiten, es sei denn, wir können
      zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
      Interessen, Rechte und Freiheiten überwiegen, oder wenn die Verarbeitung
      der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.
    </p>
    <p>
      Dies gilt nicht, wenn die Verarbeitung zu Zwecken des Direktmarketings
      erfolgt. Dann werden wir Ihre personenbezogenen Daten nicht weiter zu
      diesem Zweck verarbeiten.
    </p>
    <p>
      <a href="https://shop.trustedshops.com/de/rechtstexte/" target="_blank"
        >Datenschutzerklärung</a
      >
      erstellt mit dem
      <a href="https://shop.trustedshops.com/de/" target="_blank"
        >Trusted Shops</a
      >
      Rechtstexter in Kooperation mit
      <a href="https://foehlisch.com" target="_blank">FÖHLISCH Rechtsanwälte</a
      >.
    </p>
  </div>
</main>
<div class="footer background-light">
  <p>
    Peter Mokroß<br />
    Geschäftsführung<br />
    <br />
    U-Trainer® Fitness<br />
    <br />
    <br />
    +49 177 7944895<br />
    <br />
    rmokross@u-trainer-fitness.com<br />
    <br />
    u-trainer-fitness.com<br />
    <br />
    Schoellerweg 34, 42327 Wuppertal<br />
  </p>
</div>
