import { Component, OnInit } from '@angular/core';
import { SidenavService } from '../../service/sidenav.service';
import { CardService } from 'src/app/service/card.service';
import { products } from '../../mock/products';
import { ControllerService } from '../../service/controller.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  langs = ['en', 'de'];

  countries: Map<string, string> = new Map<string, string>();

  get products() {
    return products;
  }

  get template() {
    return this.service.template;
  }

  get card() {
    return this.cardService.card;
  }

  get totalPrice() {
    let price = 0;
    this.card.forEach((value: number, key: number) => {
      price += products[key].price * value;
    });
    return price;
  }

  get totalCardItems() {
    return this.cardService.totalCardItems;
  }

  constructor(
    private service: SidenavService,
    private cardService: CardService,
    public controller: ControllerService,
    public translate: TranslateService) { }

  ngOnInit() {
    this.countries.set('en', 'English');
    this.countries.set('de', 'German');
  }

  toggle() {
    this.service.toggle();
  }

  switchLang(lang: string) {
    this.controller.switchLang(lang);
  }

}
