<main>
  <h1>Widerrufsbelehrung</h1>
  <div>
    <h2>Widerrufsrecht</h2>
    <p>
      Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen
      Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag
      an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer
      ist, die letzte Ware in Besitz genommen haben bzw. hat.
    </p>
    <p>
      Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Peter Mokroß, Schöllerweg
      34, 42327 Wuppertal, Deutschland, mail@u-trainer-fitness.com, Telefon:
      00491777944895) mittels einer eindeutigen Erklärung (z. B. ein mit der
      Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen
      Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte
      Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
    </p>
    <p>
      Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über
      die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
    </p>
  </div>
  <div>
    <h2>Folgen des Widerrufs</h2>
    <p>
      Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die
      wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit
      Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine
      andere Art der Lieferung als die von uns angebotene, günstigste
      Standardlieferung gewählt haben), unverzüglich und spätestens binnen
      vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren
      Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung
      verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen
      Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich
      etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser
      Rückzahlung Entgelte berechnet. Wir können die Rückzahlung verweigern, bis
      wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis
      erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem,
      welches der frühere Zeitpunkt ist.
    </p>
    <p>
      Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen
      vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses
      Vertrags unterrichten, an uns zurückzusenden oder zu übergeben. Die Frist
      ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen
      absenden. Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.
      Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn
      dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften
      und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen
      zurückzuführen ist.
    </p>
  </div>
  <div>
    <h2>Muster-Widerrufsformular</h2>
    <p>
      (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses
      Formular aus und senden Sie es zurück.)
    </p>
    <ul>
      <li>
        An Peter Mokroß, Schöllerweg 34, 42327 Wuppertal, Deutschland,
        mail@u-trainer-fitness.com
      </li>
      <li>
        Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen
        Vertrag über den Kauf der folgenden Waren (*)/die Erbringung der
        folgenden Dienstleistung (*)
      </li>
      <li>Bestellt am (*)/erhalten am (*)</li>
      <li>Name des/der Verbraucher(s)</li>
      <li>Anschrift des/der Verbraucher(s)</li>
      <li>
        Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
      </li>
      <li>Datum</li>
    </ul>
    <p>(*) Unzutreffendes streichen.</p>
  </div>
  <p>
    <a href="https://shop.trustedshops.com/de/rechtstexte/"
      >Widerrufsbelehrung</a
    >
    erstellt mit dem
    <a href="https://shop.trustedshops.com/de/">Trusted Shops</a> Rechtstexter
    in Kooperation mit
    <a href="https://foehlisch.com">FÖHLISCH Rechtsanwälte</a>
  </p>
</main>
<div class="footer background-light">
  <p>
    Peter Mokroß<br />
    Geschäftsführung<br />
    <br />
    U-Trainer® Fitness<br />
    <br />
    <br />
    +49 177 7944895<br />
    <br />
    rmokross@u-trainer-fitness.com<br />
    <br />
    u-trainer-fitness.com<br />
    <br />
    Schoellerweg 34, 42327 Wuppertal<br />
  </p>
</div>
