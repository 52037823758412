import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { config } from 'rxjs';
import { Product } from '../entities/product';
import { products } from '../mock/products';

@Injectable({
  providedIn: 'root'
})
export class ControllerService {

  breakpoint: Map<number, number> = new Map<number, number>();
  agbAccepted: boolean = false;
  cookies: Map<string, string> = new Map<string, string>();
  language: string;
  homeBenefits;
  _product: Product;
  products: Product[];
  private _session: string;
  private charset = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

  get session() {
    return this._session;
  }

  get screenRes() {
    let val = 0;
    this.breakpoint.forEach((value: number, key: number) => {
      if (window.innerWidth <= key) {
        val = value;
      }
    });
    return val;
  }

  get product() {
    this.setProdsLang(this._product.id);
    return this._product;
  }

  constructor(
      private cookie: CookieService,
      private translate: TranslateService) {
    this.initBreakpoints();
    this.initLang();
    this._session = cookie.check('session') ? cookie.get('session') : this.createSession();
  }

  private initBreakpoints() {
    this.breakpoint.set(1200, 0);
    this.breakpoint.set(992, 1);
    this.breakpoint.set(768, 2);
    this.breakpoint.set(576, 3);
  }

  saveCookie(name: string, val: string, expireDate?: Date) {
    localStorage.setItem(name, val);
  }

  getCookie(name: string): string {
    return localStorage.getItem(name);
  }

  removeCookie(name: string) {
    if (localStorage.getItem(name))
      localStorage.removeItem(name);
  }

  private createSession(): string {
    let id = '';
    const expire = new Date();
    expire.setDate(expire.getDate() + 1);
    for (let i = 0; i < 16; i++) {
      id += this.charset.charAt(Math.floor(Math.random() * this.charset.length));
    }

    this.cookie.set('session', id);
    return id;
  }

  async delay(ms: number) {
    return await new Promise( resolve => setTimeout(resolve, ms) );
  }

  initLang() {
    this.translate.addLangs(['de', 'en']);
    this.translate.setDefaultLang('de');
    if (this.cookie.check('config')) {
      this.language = this.cookie.get('config');
      this.translate.use(this.cookie.get('config'));
    }
    this.translate.get('home.benefits').subscribe(bene => this.homeBenefits = bene);
  }

  switchLang(lang: string) {
    this.language = lang;
    this.cookie.set('config', lang, 360, '/');
    this.saveCookie('language', lang);
    this.translate.use(lang);
    this.translate.get('home.benefits').subscribe(bene => this.homeBenefits = bene);
    this.setProdsLang(this._product.id);
  }

  setProdsLang(id) {
    this.translate.get('item.' + id + '.description').subscribe((val: string) => this._product.description = val);
    this.translate.get('item.' + id + '.name').subscribe((val: string) => this._product.name = val);
  }

  getProdsLang(id): Product {
    const product = products.find(prod => prod.id === id);
    this.translate.get('item.' + id + '.description').subscribe((val: string) => product.description = val);
    this.translate.get('item.' + id + '.name').subscribe((val: string) => product.name = val);
    return product;
  }
}
