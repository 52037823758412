import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { Product } from '../../entities/product';
import { products } from '../../mock/products';
import { ControllerService } from '../../service/controller.service';


@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {

  products = products;

  constructor(
    private controller: ControllerService,
    private title: Title,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.products = products.map(prod => prod = this.controller.getProdsLang(prod.id));
    this.translate.get("titles.shop").subscribe(title => this.title.setTitle(title));
  }

}
