import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable()
export class SidenavService {
  private sidenav: MatSidenav;
  private _template: 'sidenav' | 'card' = 'sidenav';

  set template(url: 'sidenav' | 'card') {
    this._template = url;
  }

  get template(): 'sidenav' | 'card' {
    return this._template;
  }

  public setSidenav(sidenav: MatSidenav) {
      this.sidenav = sidenav;
  }

  public open() {
      return this.sidenav.open();
  }


  public close() {
      return this.sidenav.close();
  }

  public toggle(url: 'sidenav' | 'card' = 'sidenav'): void {
    this.template = url;
    this.sidenav.toggle();
  }
}
