<main>
  <h1>Allgemeine Geschäftsbedingungen</h1>
  <div>
    <h2>1. Geltungsbereich</h2>
    <p>
      Für alle Bestellungen über unseren Online-Shop gelten die nachfolgenden
      AGB. Unser Online-Shop richtet sich ausschließlich an Verbraucher.
    </p>
    <p>
      Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken
      abschließt, die überwiegend weder ihrer gewerblichen noch ihrer
      selbständigen beruflichen Tätigkeit zugerechnet werden können. Unternehmer
      ist eine natürliche oder juristische Person oder eine rechtsfähige
      Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung
      ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt.
    </p>
  </div>
  <div>
    <h2>2. Vertragspartner, Vertragsschluss, Korrekturmöglichkeiten</h2>
    <p>Der Kaufvertrag kommt zustande mit U-Trainer-Fitness R. Mokroß.</p>
    <p>
      Die Darstellung der Produkte im Online-Shop stellt kein rechtlich
      bindendes Angebot, sondern einen unverbindlichen Online-Katalog dar. Sie
      können unsere Produkte zunächst unverbindlich in den Warenkorb legen und
      Ihre Eingaben vor Absenden Ihrer verbindlichen Bestellung jederzeit
      korrigieren, indem Sie die hierfür im Bestellablauf vorgesehenen und
      erläuterten Korrekturhilfen nutzen. Durch Anklicken des Bestellbuttons
      geben Sie eine verbindliche Bestellung der im Warenkorb enthaltenen Waren
      ab. Die Bestätigung des Zugangs Ihrer Bestellung erfolgt per E-Mail
      unmittelbar nach dem Absenden der Bestellung.
    </p>
    <p>
      Wann der Vertrag mit uns zustande kommt, richtet sich nach der von Ihnen
      gewählten Zahlungsart:
    </p>
    <div class="payment-method">
      <h3>PayPal Plus</h3>
      <p>
        Im Rahmen des Zahlungsdienstes PayPal Plus bieten wir Ihnen verschiedene
        Zahlungsmethoden als PayPal Services an. Nach Abgabe der Bestellung
        werden Sie auf die Webseite des Online-Anbieters PayPal weitergeleitet.
        Dort können Sie Ihre Zahlungsdaten angeben und die Zahlungsanweisung an
        PayPal bestätigen. Dadurch kommt der Vertrag mit uns zustande.
      </p>
    </div>
    <div class="payment-method">
      <h3>Sofort by Klarna</h3>
      <p>
        Nach Abgabe der Bestellung werden Sie auf die Webseite des
        Online-Anbieters Sofort GmbH weitergeleitet, wo Sie die
        Zahlungsanweisung bestätigen. Dadurch kommt der Vertrag mit uns
        zustande.
      </p>
    </div>
    <div class="payment-method">
      <h3>Giropay</h3>
      <p>
        Nach Abgabe der Bestellung werden Sie auf die Webseite Ihrer Bank
        weitergeleitet, wo Sie die Zahlungsanweisung an Ihre Bank bestätigen.
        Dadurch kommt der Vertrag mit uns zustande.
      </p>
    </div>
  </div>
  <div>
    <h2>3. Vertragssprache, Vertragstextspeicherung</h2>
    <p>
      Die für den Vertragsschluss zur Verfügung stehenden Sprachen sind Deutsch
      und Englisch.
    </p>
    <p>
      Wir speichern den Vertragstext und senden Ihnen die Bestelldaten und
      unsere AGB in Textform zu. Der Vertragstext ist aus Sicherheitsgründen
      nicht mehr über das Internet zugänglich.
    </p>
  </div>
  <div>
    <h2>4. Lieferbedingungen</h2>
    <p>
      Zuzüglich zu den angegebenen Produktpreisen kommen noch Versandkosten
      hinzu. Näheres zur Höhe der Versandkosten erfahren Sie bei den Angeboten.
    </p>
    <p>
      Wir liefern nur im Versandweg. Eine Selbstabholung der Ware ist leider
      nicht möglich.
    </p>
    <p>Wir liefern nicht an Packstationen.</p>
  </div>
  <div>
    <h2>5. Bezahlung</h2>
    <p>
      In unserem Shop stehen Ihnen grundsätzlich die folgenden Zahlungsarten zur
      Verfügung:
    </p>

    <div class="payment-method">
      <h3>PayPal Plus</h3>
      <p>
        Im Rahmen des Zahlungsdienstes PayPal Plus bieten wir Ihnen verschiedene
        Zahlungsmethoden als PayPal Services an. Sie werden auf die Webseite des
        Online-Anbieters PayPal weitergeleitet. Dort können Sie Ihre
        Zahlungsdaten angeben, die Verwendung Ihrer Daten durch PayPal und die
        Zahlungsanweisung an PayPal bestätigen.
      </p>
      <p>
        Wenn Sie die Zahlungsart PayPal gewählt haben, müssen Sie, um den
        Rechnungsbetrag bezahlen zu können, dort registriert sein bzw. sich erst
        registrieren und mit Ihren Zugangsdaten legitimieren. Die
        Zahlungstransaktion wird von PayPal unmittelbar nach Bestätigung der
        Zahlungsanweisung automatisch durchgeführt. Weitere Hinweise erhalten
        Sie beim Bestellvorgang.
      </p>
    </div>
    <div class="payment-method">
      <h3>Kreditkarte über PayPal</h3>
      <p>
        Wenn Sie die Zahlungsart Kreditkarte gewählt haben, müssen Sie, um den
        Rechnungsbetrag bezahlen zu können, bei PayPal nicht registriert sein.
        Die Zahlungstransaktion wird unmittelbar nach Bestätigung der
        Zahlungsanweisung und nach Ihrer Legitimation als rechtmäßiger
        Karteninhaber von Ihrem Kreditkartenunternehmen auf Aufforderung von
        PayPal durchgeführt und Ihre Karte belastet. Weitere Hinweise erhalten
        Sie beim Bestellvorgang.
      </p>
    </div>

    <div class="payment-method">
      <h3>Lastschrift via PayPal</h3>
      <p>
        Wenn Sie die Zahlungsart Lastschrift gewählt haben, müssen Sie, um den
        Rechnungsbetrag bezahlen zu können, bei PayPal nicht registriert sein.
        Mit Bestätigung der Zahlungsanweisung erteilen Sie PayPal ein
        Lastschriftmandat. Über das Datum der Kontobelastung werden Sie von
        PayPal informiert (sog. Prenotification). Unter Einreichung des
        Lastschriftmandats unmittelbar nach Bestätigung der Zahlungsanweisung
        fordert PayPal seine Bank zur Einleitung der Zahlungstransaktion auf.
        Die Zahlungstransaktion wird durchgeführt und Ihr Konto belastet.
        Weitere Hinweise erhalten Sie beim Bestellvorgang.
      </p>
    </div>

    <div class="payment-method">
      <h3>Rechnung via PayPal</h3>
      <p>
        Wenn Sie die Zahlungsart Rechnung gewählt haben, müssen Sie, um den
        Rechnungsbetrag bezahlen zu können, bei PayPal nicht registriert sein.
        Nach erfolgreicher Adress- und Bonitätsprüfung und Abgabe der Bestellung
        treten wir unsere Forderung an PayPal ab. Sie können in diesem Fall nur
        an PayPal mit schuldbefreiender Wirkung leisten. Für die
        Zahlungsabwicklung über PayPal gelten – ergänzend zu unseren AGB – die
        AGB und die Datenschutzerklärung von PayPal. Weitere Informationen und
        die vollständigen AGB von PayPal zum Rechnungskauf finden Sie
        <a
          href="https://www.paypal.com/de/webapps/mpp/ua/pui-terms?locale.x=de_DE"
          target="_blank"
          >hier</a
        >.
      </p>
    </div>

    <div class="payment-method">
      <h3>Sofort by klarna</h3>
      <p>
        Nach Abgabe der Bestellung werden Sie auf die Webseite des
        Online-Anbieters Sofort GmbH weitergeleitet. Um den Rechnungsbetrag über
        Sofort bezahlen zu können, müssen Sie über ein für Online-Banking
        freigeschaltetes Bankkonto verfügen, sich entsprechend legitimieren und
        die Zahlungsanweisung an uns bestätigen. Weitere Hinweise erhalten Sie
        beim Bestellvorgang. Die Zahlungstransaktion wird unmittelbar danach von
        Sofort durchgeführt und Ihr Konto belastet.
      </p>
    </div>

    <div class="payment-method">
      <h3>Giropay</h3>
      <p>
        Nach Abgabe der Bestellung werden Sie auf die Webseite Ihrer Bank
        weitergeleitet. Um den Rechnungsbetrag über Giropay bezahlen zu können,
        müssen Sie über ein für Online-Banking freigeschaltetes Bankkonto
        verfügen, sich entsprechend legitimieren und die Zahlungsanweisung an
        uns bestätigen. Weitere Hinweise erhalten Sie beim Bestellvorgang. Die
        Zahlungstransaktion wird unmittelbar danach durchgeführt und Ihr Konto
        belastet.
      </p>
    </div>
  </div>
  <div>
    <h2>6. Streitbeilegung</h2>
    <p>
      Die Europäische Kommission stellt eine Plattform zur
      Online-Streitbeilegung (OS) bereit, die Sie
      <a href="https://ec.europa.eu/consumers/odr/" target="_blank">hier</a>
      finden. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer
      Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht
      bereit.
    </p>
    <p>
      <a href="https://shop.trustedshops.com/de/rechtstexte/" target="_blank"
        >AGB</a
      >
      erstellt mit dem Trusted
      <a href="https://shop.trustedshops.com/de/" target="_blank">Shops</a>
      Rechtstexter in Kooperation mit
      <a href="https://foehlisch.com" target="_blank">FÖHLISCH Rechtsanwälte</a
      >.
    </p>
  </div>
</main>
<div class="footer background-light">
  <p>
    Peter Mokroß<br />
    Geschäftsführung<br />
    <br />
    U-Trainer® Fitness<br />
    <br />
    <br />
    +49 177 7944895<br />
    <br />
    rmokross@u-trainer-fitness.com<br />
    <br />
    u-trainer-fitness.com<br />
    <br />
    Schoellerweg 34, 42327 Wuppertal
  </p>
</div>
