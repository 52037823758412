import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ControllerService } from '../../service/controller.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  
  constructor(
    public readonly controller: ControllerService,
    private readonly title: Title,
    private readonly translate: TranslateService) { }

  ngOnInit() {
    this.translate.get("titles.faq").subscribe(title => this.title.setTitle(title));
  }

}
