<main *ngIf="controller.screenRes <= 1" class="container">
    <h1>{{'trainer.title' | translate}} U-TRAINER®</h1>

    <div class="text">
        <p style="column-count: 2;" [innerHTML]="'trainer.text' | translate"></p>
    </div>
</main>

<main *ngIf="controller.screenRes > 1" class="container-mb"> 
    <div class="text">
        <h1>{{'trainer.title' | translate}} U-TRAINER®</h1>
        <p [innerHTML]="'trainer.text' | translate"></p>
    </div>
</main>