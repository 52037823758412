import {Directive, Input, ElementRef, HostListener, AfterViewInit } from '@angular/core';


@Directive({
  selector: '[bgParallax]'
})
export class ParallaxDirective implements AfterViewInit {
  @Input() ratio: number = 1;
  @Input() image: HTMLImageElement;

  private hasError: boolean = false;
  private loadedImage;

  constructor(private el: ElementRef) {
  }

  ngAfterViewInit() {
    if (!this.image) {
      this.hasError = true;
      console.error('[Parallax] No Background given');
      return;
    }

    this.el.nativeElement.style.backgroundImage = `url(${this.image.src})`;

    this.image.onload = (e) => {
      this.loadedImage = e.currentTarget;
      this.setBackgroundPos();
    }

    this.setBackgroundPos();
  }

  @HostListener('window:scroll', ['$event']) onWindowScroll(event) {
    this.setBackgroundPos();
  }

  @HostListener('window:resize', ['$event']) onWindowResize(event) {
    this.setBackgroundPos();
  }

  setBackgroundPos() {
    if (!this.hasError) {
      const relativeRatio = window.scrollY * this.ratio;
      const imageProp = this.image.width / this.image.height;
      const relativeImageHeigt = this.el.nativeElement.clientWidth * imageProp;
      const imageHeightOffset = (this.el.nativeElement.clientHeight - relativeImageHeigt) * -1;

      if (imageHeightOffset > 0) {
        const styleValue = `calc(${relativeRatio}px - ${imageHeightOffset / 7}px)`
        this.el.nativeElement.style.backgroundPositionY = styleValue;
      }
    }
  }
}
