<main>
    <div class="container" *ngIf="controller.screenRes <= 1">
        <h1>{{'card.title' | translate}}</h1>
        <div *ngFor="let item of card | keyvalue" >
            <div class="product background-light" *ngIf="item.value > 0"
                fxLayout="row wrap"
                fxLayoutAlign=" center" 
                fxLayoutGap="1em" >

                <div fxLayout="row" fxLayoutGap="1em" fxFlex="40">
                    <img src="{{item?.key?.pics[0]}}" alt="product">
                    <div>
                        <h3>{{item.key.name}}</h3>
                        <h4>{{item.key.price | number: '1.2-2'}}€</h4>
                    </div>
                </div>
                <div fxFlex="20">
                    <p>{{'card.amount' | translate}}: {{item.value}}</p>
                </div>
                <div fxFlex="20">
                    <button mat-icon-button (click)="increase(item.key)"><i class="material-icons">add</i></button>
                    <button mat-icon-button (click)="decrease(item.key)"><i class="material-icons">remove</i></button>
                    <button mat-icon-button (click)="delete(item.key)"><i class="material-icons">delete</i></button>
                </div>
                <div fxFlex style="text-align: right;"><h3>{{totalPriceOfProduct(item.key)  | number: '1.2-2'}}€</h3></div>
            </div>
        </div>

        <div *ngIf="totalCardItems > 0" style="text-align: right;">
            <div stlye="padding-bottom: 5em;">
                <h4 *ngIf="couponVal > 0" style="margin: 0;">{{'card.coupon' | translate}} {{couponVal}}%</h4>
                <h2 style="margin: 0.5em 0 0;">{{'card.total' | translate}}: {{totalPrice | number: '1.2-2'}}€</h2>
                <h5 style="margin-top: 0">{{'card.mwst' | translate}} {{mwst | number: '1.2-2'}}€</h5>
                <!-- <mat-form-field style="margin-right: 0.25em;">
                    <mat-label>{{'card.code' | translate}}</mat-label>
                    <input matInput [(ngModel)]="coupon">
                </mat-form-field>
                <button mat-button (click)="validateCoupon()">{{'card.redeem' | translate}}</button> -->
            </div>

            <mat-checkbox [(ngModel)]="controller.agbAccepted">
                {{'card.acception' | translate}} 
                <a routerLink="">{{'card.conditions' | translate}}</a>
            </mat-checkbox><br>
            <button mat-stroked-button (click)="navigateCheckout()" [disabled]="!controller.agbAccepted">Checkout</button>
        </div>
        <h3 *ngIf="totalCardItems <= 0">{{'card.empty' | translate}}</h3>
    </div>

    <div class="container-mb" *ngIf="controller.screenRes > 1">
        <h1>{{'card.title' | translate}}</h1>
        <div *ngFor="let item of card | keyvalue" >
            <div class="product" *ngIf="item.value > 0" fxLayout="column">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="row" fxLayoutGap="1em">
                        <img src="{{item.key.pics[0]}}" alt="product">
                        <div>
                            <h3>{{item.key.name}}</h3>
                            <h4>{{item.key.price | number: '1.2-2'}}€</h4>
                        </div>
                    </div>
                    <div>
                        <button mat-icon-button (click)="increase(item.key)"><i class="material-icons">add</i></button>
                        <button mat-icon-button (click)="decrease(item.key)"><i class="material-icons">remove</i></button>
                        <button mat-icon-button (click)="delete(item.key)"><i class="material-icons">delete</i></button>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="1em" >
                    <div style="text-align: right;">
                        <p>{{'card.amount' | translate}}: {{item.value}}</p>
                    </div>
                    <div style="text-align: right;">
                        <h3>{{totalPriceOfProduct(item.key)  | number: '1.2-2'}}€</h3>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="totalCardItems > 0" style="text-align: right;">
            <h5 style="margin-bottom: 0">{{'card.coupon' | translate}} {{mwst | number: '1.2-2'}}€ MwSt</h5>
            <h2 style="margin-top: 0">{{'card.total' | translate}}: {{totalPrice | number: '1.2-2'}}€</h2>
            <!-- <mat-form-field>
                <mat-label>{{'card.code' | translate}}</mat-label>
                <input matInput>
            </mat-form-field> -->
            <a routerLink="/checkout"><button mat-stroked-button>Checkout</button></a>
        </div>
        <h3 *ngIf="totalCardItems <= 0">{{'card.empty' | translate}}</h3>
    </div>
</main>
