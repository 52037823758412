import { Product } from '../entities/product';

export const products: Product[] = [
    {
        id: 0,
        name: 'U-Trainer Fitness',
        description: '',
        price: 32.95,
        url: 'u-trainer-fitness',
        pics: [
            '../../../assets/products/utrainer/case-Holz-offen.jpeg',
            '../../../assets/products/utrainer/case-zu.jpg',
            '../../../assets/products/utrainer/u-trainer.jpg'
        ]
    },
    {
        id: 1,
        name: '',
        description: '',
        price: 2.95,
        url: 'spannring-set',
        pics: [
            '../../../assets/products/oring/O-Ringe-Shop.jpg',
        ]
    }
];
