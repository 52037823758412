
<nav *ngIf="template == 'sidenav'">
    <div class="title">
        <h2><a (click)="toggle()" routerLink="">U-TRAINER-FITNESS</a></h2>
    </div>
    
    <mat-nav-list>
        <a mat-list-item (click)="toggle()" routerLink="">Home</a>
        <a mat-list-item (click)="toggle()" routerLink="trainer">Trainer</a>
        <a mat-list-item (click)="toggle()" routerLink="training">Training</a>
        <a mat-list-item (click)="toggle()" routerLink="shop">Shop</a>
        <a mat-list-item (click)="toggle()" routerLink="info">FAQ</a>
    </mat-nav-list>
    <mat-form-field class="lang-select" fxLayoutAlign="center">
        <mat-label>{{'nav.lang' | translate}}</mat-label>
        <mat-select #selectedLang (selectionChange)="switchLang(selectedLang.value)">
            <mat-option *ngFor="let language of translate.getLangs()" [value]="language">
                {{countries.get(language)}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</nav>

<div class="card" *ngIf="template == 'card'" fxLayout="column" fxLayoutAlign="space-between none">
    <div class="mainCard" fx>
        <div class="cardTitle">
            <h3>{{'card.title' | translate}}</h3>
        </div>
        <p *ngIf="totalCardItems <= 0" style="text-align: center;">{{'card.empty' | translate}}</p>
        <div *ngFor="let item of card | keyvalue">
            <div *ngIf="item.value > 0" class="cardItem"
                routerLink="/product/{{products[item.key].id}}" 
                (click)="toggle()"
                fxLayout="row" >
                <img src="{{products[item.key].pics[0]}}" alt="product">
                <div>
                    <p>{{products[item.key].name}}</p>
                    <p>{{'product.amount' | translate}}: {{item.value}}</p>
                    <p>{{(products[item.key].price * item.value) | number: '1.2-2'}}€</p>
                </div>
            </div>
        </div>
    </div>
    
    <div class="footer" *ngIf="totalCardItems > 0">
        <p class="total">{{totalPrice | number: '1.2-2'}}€</p>
        <div class="toCheckout" routerLink="shoppingcard" (click)="toggle()">
            <span><h2>{{'card.title' | translate}}</h2></span>  
        </div>
    </div>
</div>