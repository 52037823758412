import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from '../../entities/product';
import { products } from '../../mock/products';
import { CardService } from 'src/app/service/card.service';
import { SidenavService } from '../../service/sidenav.service';
import { ControllerService } from '../../service/controller.service';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  animations: [
    trigger(
      'fade', [
        transition(':enter', [
          style({ opacity: 0, }),
            animate('2s ease-in-out',
                    style({ opacity: 1 }))
        ]),
        transition(':leave', [
          style({ opacity: 1 }),
            animate('2s ease-in-out',
                    style({ opacity: 0 }))
        ])
      ]
    )
  ]
})
export class ProductComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('prodAmount') prodAmount: ElementRef;

  paramObservable: any;
  private _picId: number = 1;

  set prodId(id) {
    this.controller._product = products.find(prod => prod.id === id);
    this.controller.setProdsLang(id);
  }

  get product(): Product  {
    return this.controller._product;
  }

  get picId() {
    return this._picId % this.controller._product.pics.length;
  }

  set picId(id: number) {
    this._picId = id;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cardService: CardService,
    private sidenav: SidenavService,
    public controller: ControllerService,
    private translate: TranslateService,
    private readonly title: Title) {}

  ngOnInit() {
    this.setProduct();
    this.translate.onTranslationChange.subscribe((params: TranslationChangeEvent) => {
      this.controller.setProdsLang(this.controller._product.id);
    });
  }

  ngAfterViewInit() {
    this.title.setTitle(this.product.name ? this.product.name : 'U-Trainer Fitness');
  }

  ngOnDestroy() {
    if (this.paramObservable != null) {
      this.paramObservable.unsubscribe();
    }
  }

  setProduct() {
    this.paramObservable = this.route.params.subscribe(params => {
      this.prodId = +this.route.snapshot.paramMap.get('id');
      this.title.setTitle(this.product.name);
      this.controller.delay(10);
    });
  }

  setCard(amount: number) {
    this.cardService.addToCard(this.product, amount);
  }

  addToCard() {
    this.cardService.addToCard(this.product, this.prodAmount.nativeElement.value);
  }

  toggle() {
    if (!this.cardService.totalCardItems) return;

    this.sidenav.toggle('card');
  }

  getValidProdClass(i: number): string {
    return this.product.id + i >= products.length || this.product.id + i < 0 ?
      'invalid-prod' :
      'valid-prod';
  }

  getProdRoute(i: number) {
    this.router.navigate(['product', this.product.id + i >= products.length ?
      products.length - 1 : this.product.id + i < 0 ? 0 : this.product.id + i]);
  }
}
