<main>
    <div class="prod-container" 
        fxLayout="row wrap" 
        fxLayoutAlign="center center" >
        <div *ngFor="let prod of products">
            <div class="product background-light" matRipple
                [ngStyle]="{'background-image':'url(' + prod.pics[0] + ')'}"
                routerLink="/product/{{prod.id}}">
                <div class="name" fxLayout="row" fxLayoutAlign="space-evenly center">
                    <span>{{prod.name}}</span>
                    <span>{{prod.price}}€</span>
                </div>
            </div>
            
        </div>
    </div>
</main>