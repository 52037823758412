import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Customer } from '../entities/customer';
import { CardService } from './card.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  private transDoc: AngularFirestoreDocument<any>;
  private transCollection: AngularFirestoreCollection<any>;
  private transactions: Observable<any[]>;

  customer: Customer;

  constructor(public db: AngularFirestore, private card: CardService) {
  }

  buildCustomer(
      _payment: any,
      _surname: string,
      _name: string,
      _email: string,
      _city: string,
      _street: string,
      _plz: string,
      _country: string,
      _company: string = '',
      _phone: string = '') {
    this.customer = {
      uid: this.db.createId(),
      payment: _payment,
      surname: _surname,
      name: _name,
      email: _email,
      address: {
        city: _city,
        street: _street,
        plz: _plz,
        country: _country
      },
      company: _company,
      phone: _phone,
      coupon: this.card.couponId
    };
    this.addCustomer(this.customer);
  }

  addCustomer(customer: any) {
    this.db.collection('customers').doc(customer.uid).set(customer);
  }
}
