import { Component, AfterViewInit, OnInit, ViewChild, Inject, PLATFORM_ID, Optional } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ControllerService } from './service/controller.service';
import { CardService } from './service/card.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SidenavService } from './service/sidenav.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements AfterViewInit, OnInit {
  langs = ['en', 'de'];
  countries: Map<string, string> = new Map<string, string>();

  @ViewChild('sidenav') public sidenav: MatSidenav;

  get totalCardItems() {
    return this.cardService.totalCardItems <= 0 ? '' : this.cardService.totalCardItems;
  }

  constructor(
    public controller: ControllerService,
    private cardService: CardService,
    private sidenavService: SidenavService,
    private _snackBar: MatSnackBar,
    public translate: TranslateService,) {}

  ngOnInit() {
    this.countries.set('en', 'English');
    this.countries.set('de', 'German');
  }

  ngAfterViewInit() {
    this.sidenavService.setSidenav(this.sidenav);
    this.cookieNotify();
  }

  toggle(url: 'sidenav' | 'card' = 'sidenav') {
    this.sidenavService.toggle(url);
  }

  async cookieNotify() {
    let msg;
    this.translate.get('cookieNotify.msg').subscribe((m: string) => msg = m );
    this.translate.get('cookieNotify.action').subscribe((a: string) => {
      this.openSnackBar(msg, a);
    });
  }

  openSnackBar(message: string, action: string) {
    if (!this.controller.cookies.has('agbAccepted')) {
      this._snackBar.open(message, action, {
        duration: 50000,
        panelClass: ['cookie-notify'],
        horizontalPosition: 'left',
      });
      const date = new Date();
      this.controller.saveCookie('agbAccepted', 'true', new Date(date.getTime() + 1 * 60000));
    }
  }

  switchLang(lang: string) {
    this.controller.switchLang(lang);
  }
}
