import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-impress',
  templateUrl: './impress.component.html',
  styleUrls: ['./impress.component.scss']
})
export class ImpressComponent implements OnInit {

  constructor(
    private readonly title: Title,
    private readonly translate: TranslateService
  ) { }

  ngOnInit() {
    this.translate.get("titles.impress").subscribe(title => this.title.setTitle(title));
  }

}
