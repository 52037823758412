import { Injectable } from '@angular/core';
import { Message } from '../entities/message';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private msgDoc: AngularFirestoreDocument<Message>;
  private msgCollection: AngularFirestoreCollection<Message>;
  private msgs: Observable<Message[]>;

  constructor(public db: AngularFirestore) {
  }

  sendMessage(msg: Message) {
    this.db.collection('messages').doc(this.db.createId()).set(msg);
  }
}
