<main fxLayout="column" *ngIf="controller.screenRes <= 1">
    <div class="prod-nav" fxLayout="row" fxLayoutAlign="space-between center">
        <a routerLink="/shop">{{'product.toShop' | translate}}</a>
        <p fxLayout="row" fxLayoutAlign=" center">
            <i class="material-icons">navigate_before</i>
            <a (click)="getProdRoute(-1)" class="{{getValidProdClass(-1)}}">{{'product.previous' | translate}}</a>|
            <a (click)="getProdRoute(1)" class="{{getValidProdClass(1)}}">{{'product.next' | translate}}</a>
            <i class="material-icons">navigate_next</i>
        </p>
    </div>
    <div class="container" fxLayout="row" fxLayoutGap="1em">
        <div class="img-select" fxLayout="column" fxLayoutGap="0.25em" fxFlex="5">
            <img *ngFor="let pic of product.pics; let i = index" src="{{pic}}" (click)="picId = i" alt="product"/>
        </div>
        <div fxFlex="70" class="img background-light"
            [ngStyle]="{'background-image':'url(' + product.pics[picId] + ')'}">
        </div>
        <div fxLayout="column" fxLayoutGap="0.25em" fxFlex="25">
            <h1>{{product.name}}</h1>
            <h3>{{product.price | number: '1.2-2'}}€</h3>
            <p>{{product.description}}</p>
            <div>
                <p style="margin-bottom: 0;">{{'product.amount' | translate}}:</p>
                <form>
                    <input class="input" type="number" #prodAmount value="1"/>
                </form>
                <button mat-stroked-button (click)="addToCard(); toggle()" >{{'product.toCard' | translate}}</button>
            </div>
        </div>
    </div>

    <table class="info">
        <tr>
            <td class="v-line" valign="top">
                <h3>{{'product.info.title1' | translate}}</h3>
                <p [innerHTML]="'product.info.text1' | translate"></p>
            </td>
            <td class="v-line" style="padding-left: 2em" valign="top">
                <h3>{{'product.info.title2' | translate}}</h3>
                <p [innerHTML]="'product.info.text2' | translate"></p>
                <a routerLink="/revocation">{{'product.info.readmore' | translate}}</a>
            </td>
            <td style="padding-left: 2em" valign="top">
                <h3>{{'product.info.title3' | translate}}</h3>
                <p [innerHTML]="'product.info.text3' | translate"></p>
            </td>
        </tr>
    </table>
</main>


<main fxLayout="column" *ngIf="controller.screenRes >= 2" class="mb">
    <div class="container-mb" fxLayout="column" fxLayoutGap="1em">
        <div class="img-mb background-light"
            [ngStyle]="{'background-image':'url(' + product.pics[picId] + ')'}">
        </div>
        <div class="img-select" fxLayout="row" fxLayoutAlign="center" fxLayoutGap="0.25em" fxFlex="5">
            <img *ngFor="let pic of product.pics; let i = index" src="{{pic}}" (click)="picId = i" alt="product"/>
        </div>
        <div style="width: 70%" fxLayout="column" fxLayoutGap="0.25em">
            <h1>{{product.name}}</h1>
            <h3>{{product.price | number: '1.2-2'}}€</h3>
            <p>{{product.description}}</p>
            <div>
                <form style="margin-bottom: 0.5em;">
                    {{'product.amount' | translate}}: <input class="input" type="number" #prodAmount value="1"/>
                </form>
                <button mat-stroked-button (click)="addToCard(); toggle()" >{{'product.toCard' | translate}}</button>
            </div>
        </div>
    </div>

    <table class="info-mb">
        <tr class="v-line" valign="top">
            <h3>{{'product.info.title1' | translate}}</h3>
            <p [innerHTML]="'product.info.text1' | translate"></p>
        </tr>
        <tr class="v-line" style="padding-left: 2em" valign="top">
            <h3>{{'product.info.title2' | translate}}</h3>
            <p [innerHTML]="'product.info.text2' | translate"></p>
            <a routerLink="/revocation">{{'product.info.readmore' | translate}}</a>
        </tr>
        <tr style="padding-left: 2em" valign="top">
            <h3>{{'product.info.title3' | translate}}</h3>
            <p [innerHTML]="'product.info.text3' | translate"></p>
        </tr>
    </table>
</main>
