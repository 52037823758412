<main>
    <div class="container{{screen}}">
        <div *ngIf="paidfor">
            <h1>{{'checkout.successPayHeader' | translate}}</h1>
            <a routerLink="">{{'checkout.successPayLink' | translate}}</a>
        </div>
        <h1 style="margin-top: 0;" [hidden]="paidfor || totalCardItems <= 0 || !controller.agbAccepted">
            {{'card.title' | translate}}
        </h1>
        <div [hidden]="paidfor  || totalCardItems <= 0 || !controller.agbAccepted" 
            fxLayout="column"
            fxLayoutAlign="space-between start"
            fxLayoutAlign.sm="center center"
            fxLayoutAlign.xs="center center"
            fxLayoutGap="1em">
            
            
            <div style="width:100%">
                <div *ngFor="let item of card | keyvalue" >
                    <div class="product{{screen}} background-light" *ngIf="item.value > 0" fxLayout="row wrap" fxLayoutAlign="space-between center">
    
                        <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign=" center">
                            <img src="{{item.key.pics[0]}}" alt="product">
                            <h3>{{item.key.name}}</h3>
                        </div>
    
                        <div style="text-align: right;">
                            <h3>{{totalPriceOfProduct(item.key)  | number: '1.2-2'}}€</h3>
                        </div>
                        
                    </div>
                </div>
                <h5 style="text-align: right; margin-bottom: 0">{{'checkout.inclusive' | translate}} {{mwst | number: '1.2-2'}}€ MwSt</h5>
                <h1 style="text-align: right; margin-top: 0;">{{'checkout.total' | translate}}: {{totalPrice | number: '1.2-2'}}€</h1>
            </div>
            <div 
                fxLayout="row" 
                fxLayout.sm="column"
                fxLayout.xs="column"
                fxLayoutAlign.sm="center center"
                fxLayoutAlign.xs="center center"
                fxLayoutAlign="space-between start" 
                fxLayoutGap="1em">
                <div *ngIf="!completedForm">
                    <h3 style="text-align: center;">{{'checkout.enterInfo' | translate}}</h3>
                </div>
                <div [hidden]="!completedForm" #paypal></div>
                <div class="cust-form">
                    <div class="v-line">
                        <mat-form-field>
                            <input matInput #input placeholder="{{'checkout.surname' | translate}}*" (keyup)="surname = $event.target.value" [formControl]="surnameForm">
                            <mat-error *ngIf="surnameForm.hasError('required')">{{'checkout.errReq' | translate}}</mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput #input placeholder="{{'checkout.name' | translate}}*" (keyup)="name = $event.target.value" [formControl]="nameForm">
                            <mat-error *ngIf="nameForm.hasError('required')">{{'checkout.errReq' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                    <mat-form-field>
                        <input matInput #input placeholder="E-Mail*" (keyup)="email = $event.target.value" [formControl]="emailForm">
                        <mat-error *ngIf="emailForm.hasError('email') && !emailForm.hasError('required')">
                            {{'checkout.errMail' | translate}}
                        </mat-error>
                        <mat-error *ngIf="emailForm.hasError('required')">{{'checkout.errReq' | translate}}</mat-error>
                    </mat-form-field>
                    <div>
                        <div class="v-line">
                            <mat-form-field>
                                <input matInput #input placeholder="{{'checkout.postalcode' | translate}}*" (keyup)="plz = $event.target.value" [formControl]="plzForm">
                                <mat-error *ngIf="plzForm.hasError('required')">{{'checkout.errReq' | translate}}</mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput #input placeholder="{{'checkout.city' | translate}}*" (keyup)="city = $event.target.value" [formControl]="cityForm">
                                <mat-error *ngIf="cityForm.hasError('required')">{{'checkout.errReq' | translate}}</mat-error>
                            </mat-form-field>
                        </div>
                        <mat-form-field>
                            <input matInput #input placeholder="{{'checkout.street' | translate}}*" (keyup)="street = $event.target.value" [formControl]="streetForm">
                            <mat-error *ngIf="streetForm.hasError('required')">{{'checkout.errReq' | translate}}</mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput #input placeholder="{{'checkout.country' | translate}}*" (keyup)="country = $event.target.value" [formControl]="countryForm">
                            <mat-error *ngIf="countryForm.hasError('required')">{{'checkout.errReq' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                    <mat-form-field>
                        <input matInput #input placeholder="{{'checkout.company' | translate}}" (keyup)="company = $event.target.value">
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput #input placeholder="{{'checkout.phone' | translate}}" (keyup)="phone = $event.target.value" [formControl]="phoneForm">
                        <mat-error *ngIf="phoneForm.hasError('required')">{{'checkout.errReq' | translate}}</mat-error>
                    </mat-form-field>
                    
                </div>
                
            </div>
        </div>

        <div [hidden]="paidfor || totalCardItems > 0">
            <h1>{{'checkout.empty' | translate}}</h1>
            <a routerLink="/shop">{{'checkout.emptyLink' | translate}}</a>
        </div>
        <div *ngIf="!controller.agbAccepted && totalCardItems > 0" style="text-align: center;">
            <h1>{{'checkout.acceptConditions' | translate}}</h1>
            <mat-checkbox [(ngModel)]="controller.agbAccepted">{{'card.acception' | translate}} <a routerLink="/conditions">{{'card.conditions' | translate}}</a></mat-checkbox>
        </div>
    </div>
    
</main>

