import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './page/home/home.component';
import { TrainerComponent } from './page/trainer/trainer.component';
import { TrainingComponent } from './page/training/training.component';
import { ShopComponent } from './page/shop/shop.component';
import { FaqComponent } from './page/faq/faq.component';
import { ProductComponent } from './page/product/product.component';
import { CardComponent } from './page/card/card.component';
import { CheckoutComponent } from './page/checkout/checkout.component';
import { ImpressComponent } from './page/impress/impress.component';
import { PrivacyComponent } from './page/privacy/privacy.component';
import { CondidtionsComponent } from './page/condidtions/condidtions.component';
import { RevocationComponent } from './page/revocation/revocation.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'trainer', component: TrainerComponent },
  { path: 'training', component: TrainingComponent },
  { path: 'shop', component: ShopComponent },
  { path: 'info', component: FaqComponent },
  { path: 'product/:id', component: ProductComponent},
  { path: 'shoppingcard', component: CardComponent },
  { path: 'checkout', component: CheckoutComponent},
  { path: 'imprint', component: ImpressComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'conditions', component: CondidtionsComponent },
  { path: 'revocation', component: RevocationComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
