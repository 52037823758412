import { Observable, of } from 'rxjs';
import { products } from './../../mock/products';
import { Component, OnInit, NgModule } from '@angular/core';
import { CardService } from '../../service/card.service';
import { Product } from '../../entities/product';
import { ControllerService } from '../../service/controller.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  coupon: string;
  card: Map<Product, number> = new Map<Product, number>();

  get couponVal() {
    return this.service.couponValue;
  }

  get totalPrice() {
    return this.service.totalPrice;
  }

  get totalCardItems() {
    return this.service.totalCardItems;
  }

  get mwst() {
    return this.service.mwst;
  }

  constructor(
    private service: CardService,
    public controller: ControllerService,
    private router: Router,
    private title: Title,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.translate.get("titles.checkout").subscribe(title => this.title.setTitle(title));
    this.updateCard();
  }

  private updateCard() {
    this.service.card.forEach((val, key) => {
      this.card.set(this.getItemById(key), val);
    })
  }

  delete(prod: Product) {
    this.service.deleteFromCard(prod);
  }

  increase(prod: Product) {
    this.service.addToCard(prod);
    this.updateCard();
  }

  decrease(prod: Product) {
    this.service.addToCard(prod, -1);
    this.updateCard();
  }

  setAmount(prod: Product, amount: number) {
    this.service.setCard(prod, amount);
    this.updateCard();
  }

  totalPriceOfProduct(prod: Product): number {
    return this.service.totalPriceOfProduct(prod);
  }

  navigateCheckout() {
    this.router.navigate(['checkout']);
  }

  validateCoupon() {
    this.service.getCoupon(this.coupon);
    this.coupon = '';
  }

  getItemById(id: number): Product | undefined {
    return products.find(prod => prod.id === id);
  }
}
