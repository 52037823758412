import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ControllerService } from '../../service/controller.service';
import { TransactionService } from '../../service/transaction.service';
import { CardService } from '../../service/card.service';
import { Product } from '../../entities/product';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { products } from 'src/app/mock/products';

declare var paypal;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, AfterViewInit {
  @ViewChild('paypal', {static: true}) paypalElement: ElementRef;

  paidfor = false;
  completeForm = false;
  surname: string;
  name: string;
  email: string;
  plz: string;
  city: string;
  country: string;
  street: string;
  company: string;
  phone: string;
  card: Map<Product, number> = new Map<Product, number>();

  surnameForm = new FormControl('', [
    Validators.required,
  ]);

  nameForm = new FormControl('', [
    Validators.required,
  ]);

  emailForm = new FormControl('', [
    Validators.required,
    Validators.email
  ]);

  plzForm = new FormControl('', [
    Validators.required,
  ]);

  cityForm = new FormControl('', [
    Validators.required,
  ]);

  countryForm = new FormControl('', [
    Validators.required,
  ]);

  streetForm = new FormControl('', [
    Validators.required,
  ]);

  phoneForm = new FormControl('', [
    Validators.pattern('[+0-9 ]+'),
  ]);

  formControlls = [
    this.surnameForm,
    this.nameForm,
    this.emailForm,
    this.plzForm,
    this.cityForm,
    this.countryForm,
    this.streetForm,
  ];

  get screen() {
    if (this.controller.screenRes > 1) {
      return '-mb';
    }
    return '';
  }

  get completedForm(): boolean {
    let isValid = true;
    this.formControlls.forEach(form => {
      if (form.invalid) {
        isValid = false;
      }
    });
    return isValid;
  }

  get mwst() {
    return this.cardService.mwst;
  }

  get totalCardItems() {
    return this.cardService.totalCardItems;
  }

  get totalPrice() {
    return this.cardService.totalPrice;
  }

  constructor(
    private cardService: CardService,
    public controller: ControllerService,
    private title: Title,
    private translate: TranslateService,
    private transService: TransactionService) { }

  ngOnInit() {
    this.translate.get('titles.checkout').subscribe(title => this.title.setTitle(title));
    this.updateCard();
  }

  ngAfterViewInit() {
    this.initPaypal();
  }

  private updateCard() {
    this.cardService.card.forEach((val, key) => {
      this.card.set(this.getItemById(key), val);
    })
  }

  initPaypal() {
    paypal.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  currency_code: 'EUR',
                  value: this.cardService.totalPrice,
                  breakdown: {
                    item_total: {
                      currency_code: 'EUR',
                      value: this.cardService.totalPrice
                    }
                  }
                },
                items: this.cardService.itemList,
              }
            ]
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          this.paidfor = true;
          this.cardService.resetCard();
          this.transService.buildCustomer(
            order,
            this.surname,
            this.name,
            this.email,
            this.city,
            this.street,
            this.plz,
            this.country,
            this.company,
            this.phone
          );
          this.controller.agbAccepted = false;
        },
        onError: err => {
          console.error(err);
        }
      }).render(this.paypalElement.nativeElement);
  }

  totalPriceOfProduct(prod: Product): number {
    return this.cardService.totalPriceOfProduct(prod);
  }

  getItemById(id: number): Product | undefined {
    return products.find(prod => prod.id === id);
  }
}
