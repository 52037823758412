<!--The content below is only a placeholder and can be replaced.-->



<mat-sidenav-container class="theme-light">
  <mat-sidenav #sidenav position="end">
    <sidenav></sidenav>
  </mat-sidenav>

  <mat-sidenav-content fxLayout="column">
    <header>
      <div class="title">
          <a routerLink=""><img src="../../../assets/logo.jpg" alt="logo"></a>
      </div>
      
      <nav *ngIf="controller.screenRes <= 1" fxLayout="row" fxLayoutAlign=" center" >
        <div class="nav-list">
          <ul fxLayout="row" fxLayoutAlign="center center">
            <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" routerLink="trainer">{{'nav.trainer' | translate}}</a></li>
            <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" routerLink="shop">{{'nav.shop' | translate}}</a></li>
            <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" routerLink="">{{'nav.home' | translate}}</a></li>
            <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" routerLink="info">{{'nav.info' | translate}}</a></li>
            <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" routerLink="training">{{'nav.training' | translate}}</a></li>
          </ul>
        </div>

        <mat-form-field class="lang-select" *ngIf="controller.screenRes <= 1">
          <mat-label>{{'nav.lang' | translate}}</mat-label>
          <mat-select #selectedLang (selectionChange)="switchLang(selectedLang.value)">
            <mat-option *ngFor="let language of translate.getLangs()" 
              [value]="language">
              {{countries.get(language)}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-icon-button  (click)="toggle('card')" style="position: absolute; right: 2em">
          <i [matBadge]="totalCardItems" class="material-icons">shopping_cart</i>
        </button>
      </nav>
      <nav *ngIf="controller.screenRes > 1" fxLayoutAlign="space-between center">
        <button mat-icon-button (click)="toggle('card')"><i [matBadge]="totalCardItems" class="material-icons">shopping_cart</i></button>
        <button mat-icon-button (click)="toggle()"><i class="material-icons">menu</i></button>
      </nav>
    </header>
    
    <router-outlet class="scrollable"></router-outlet>
    <footer>
      <div>
        <h4>U-Trainer® Fitness</h4>
        <div class="footer-nav" fxLayout="row" fxLayoutAlign="space-evenly center" >
          <div>
            <p><a routerLink="/imprint">{{'footer.imprint' | translate}}</a></p>
            <p><a routerLink="/privacy">{{'footer.privacy' | translate}}</a></p>
            <p><a routerLink="/conditions">{{'footer.conditions' | translate}}</a></p>
          </div>
          <div>
            <a>mail@u-trainer-fitness.com</a>
            <p>Schöllerweg 34</p>
            <p>42327 Wuppertal</p>
          </div>
        </div>
      </div>
      <p>©2019 U-Trainer® Fitness</p>
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>

