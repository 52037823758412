<main>
    <h1 style="text-align: center;">{{'faq.title' | translate | uppercase}}</h1>
    <table *ngIf="controller.screenRes <= 1">
        <tr>
            <td valign="top">
                <h3>{{'faq.title1' | translate | uppercase}}</h3>
                <p>{{'faq.text1' | translate}}</p>
            </td>
            <td valign="top">
                <h3>{{'faq.title2' | translate | uppercase}}</h3>
                <p>{{'faq.text2' | translate}}</p>
            </td>
        </tr>
        <tr>
            <td valign="top">
                <h3>{{'faq.title3' | translate | uppercase}}</h3>
                <p>{{'faq.text3' | translate}}</p>
            </td>
            <td valign="top">
                <h3>{{'faq.title4' | translate | uppercase}}</h3>
                <p>{{'faq.text4' | translate}}</p>
            </td>
        </tr>
    </table>

    <table *ngIf="controller.screenRes >= 2">
        <tr>
            <h3>{{'faq.title1' | translate | uppercase}}</h3>
            <p>{{'faq.text1' | translate}}</p>
        </tr>
        <tr>
            <h3>{{'faq.title2' | translate | uppercase}}</h3>
            <p>{{'faq.text2' | translate}}</p>
        </tr>
        <tr>
            <h3>{{'faq.title3' | translate | uppercase}}</h3>
            <p>{{'faq.text3' | translate}}</p>
        </tr>
        <tr>
            <h3>{{'faq.title4' | translate | uppercase}}</h3>
            <p>{{'faq.text4' | translate}}</p>
        </tr>
    </table>
</main>