<main class="cont{{screen}}">
    <div class="titleImg"
        bgParallax
        [ratio]="0.3"
        [image]="titleImage"
        fxLayout="column"
        fxLayoutAlign="center start">
    </div>
    <section class="keypoints{{screen}} background-light">
        <h1>{{'home.benefitTitle' | translate}}</h1>
        <div class="keypoint-container" fxLayout="row wrap" fxLayoutAlign="center start">
            <h4 style="text-align: center;"><i>{{'home.beneSubTitle' | translate}}</i></h4>
            <div *ngFor="let point of controller.homeBenefits; let i = index"
            fxLayout="row" 
            fxLayout.xs="column"
            fxLayoutAlign="start start"
            fxLayoutGap="0.25em"
            class="keypoint{{screen}}">
                <h3 fxFlex="8" style="text-align: center;">{{i + 1}}</h3>
                <p fxFlex innerHTML="{{point}}"></p>
            </div>
        </div>
                
    </section>
    <section *ngIf="controller.screenRes >= 2" class="tab tab-mobile" fxLayout=" center" fxFill>
        <div fxLayout="column">
            <div class="container-mb tabImg-mb" style="background-image: url(../../../assets/home_sport.jpg);"></div>
            <div #tab class="container-mb tabText background-light">
                <span>
                    <h3>{{'home.tab1topic' | translate | uppercase}}</h3>
                    <p [innerHTML]="'home.tab1text' | translate"></p>
                </span>
            </div>
        </div>
        
        <div fxLayout="column">
            <div class="container-mb tabImg-mb" style="background-image: url(../../../assets/utrainerwomen_2.jpg);"></div>
            <div #tab class="container-mb tabText background-light">
                <span>
                    <h3>{{'home.tab2topic' | translate | uppercase}}</h3>
                    <p [innerHTML]="'home.tab2text' | translate"></p>
                </span>
            </div>
        </div>
        
        <div fxLayout="column">
            <div class="container-mb tabImg-mb" style="background-image: url(../../../assets/home_quali.jpg);"></div>
            <div #tab class="container-mb tabText background-light" >
                <span>
                    <h3>{{'home.tab3topic' | translate | uppercase}}</h3>
                    <p [innerHTML]="'home.tab3text' | translate"></p>
                </span>
            </div>
        </div>
    </section>

    <section *ngIf="controller.screenRes <= 1" class="tab tab-ldesk">
        <div fxLayout="row">
            <div class="container left tabText background-light">
                <span>
                    <h3>{{'home.tab1topic' | translate | uppercase}}</h3>
                    <p [innerHTML]="'home.tab1text' | translate"></p>
                </span>
            </div>
            <div class="container right tabImg" style="background-image: url(../../../assets/home_sport.jpg);"></div>
        </div>
        
        <div fxLayout="row">
            <div class="container left tabImg background-light" style="background-image: url(../../../assets/utrainerwomen_2.jpg)"></div>
            <div #tab class="container right tabText background-light" >
                <span>
                    <h3>{{'home.tab2topic' | translate | uppercase}}</h3>
                    <p [innerHTML]="'home.tab2text' | translate"></p>
                </span>
            </div>
        </div>
        <div fxLayout="row">
            <div #tab class="container left tabText background-light" >
                <span>
                    <h3>{{'home.tab3topic' | translate | uppercase}}</h3>
                    <p [innerHTML]="'home.tab3text' | translate"></p>
                </span>
            </div>
            <div class="container right tabImg" style="background-image: url(../../../assets/home_quali.jpg);"></div>
        </div>
    </section>

    <section class="contact background-light"
        fxLayout="row"
        fxLayout.sm="column"
        fxLayout.xs="column"
        fxFill
        fxLayoutAlign="space-evenly start"
        fxLayoutAlign.sm="space-evenly center"
        fxLayoutAlign.xs="space-evenly center">
        <div style="text-align: center;">
            <h1>{{'home.contactTitle' | translate | uppercase}}</h1>
            <p>42327 Wuppertal, Schöllerweg 34</p>
            <p>mail@u-trainer-fitness.com</p>
            <p>02058 896956</p>
        </div>
        <div class="form">
            <form fxLayout="column"  fxLayoutAlign="center center">
                <div fxLayout="row" fxLayout.xs="column">
                    <mat-form-field class="left">
                        <input matInput #input placeholder="{{'home.formName' | translate}}*" (keyup)="iName = $event.target.value" [formControl]="nameFormControl">
                        <mat-error *ngIf="nameFormControl.hasError('required')"></mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput #input placeholder="E-Mail*" (keyup)="iMail = $event.target.value" [formControl]="emailFormControl">
                        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                        </mat-error>
                        <mat-error *ngIf="emailFormControl.hasError('required')">
                        </mat-error>
                    </mat-form-field>
                </div>
                <mat-form-field>
                    <input matInput #input placeholder="{{'home.formPhone' | translate}}" (keyup)="iPhone = $event.target.value" [formControl]="phoneFormControl">
                    <mat-error *ngIf="phoneFormControl.hasError('pattern')">
                        {{'home.formPhoneError' | translate}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input matInput #input placeholder="{{'home.formAddress' | translate}}" (keyup)="iAddress = $event.target.value">
                </mat-form-field>
                <mat-form-field>
                    <input matInput #input placeholder="{{'home.formSubject' | translate}}*" (keyup)="iSubject = $event.target.value" [formControl]="subjectFormControl">
                    <mat-error *ngIf="subjectFormControl.hasError('required')">
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <textarea matInput #input placeholder="{{'home.formMsg' | translate}}*" (keyup)="iMsg = $event.target.value" [formControl]="msgFormControl"></textarea>
                    <mat-error *ngIf="msgFormControl.hasError('required')">
                    </mat-error>
                </mat-form-field>
                <button mat-stroked-button (click)="sendMessage(); resetInput()">{{'home.formSend' | translate}}</button>
            </form>
        </div>
    </section>
    
</main>
