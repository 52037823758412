import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ControllerService } from '../../service/controller.service';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {

  get screen() {
    if (this.controller.screenRes > 1) {
      return '-mb';
    }
    return '';
  }

  constructor(
    private controller: ControllerService,
    private readonly title: Title) { }

  ngOnInit() {
    this.title.setTitle("Training")
  }

}
