// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyByAPOEwk3SmDtQqg3AlyslDp7T2kE5XjM",
    authDomain: "u-trainer-fitness.firebaseapp.com",
    databaseURL: "https://u-trainer-fitness.firebaseio.com",
    projectId: "u-trainer-fitness",
    storageBucket: "u-trainer-fitness.appspot.com",
    messagingSenderId: "699988849795",
    appId: "1:699988849795:web:5b8aaa93d39c5d9a5057b6",
    measurementId: "G-Y7KHWNWMMN"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
