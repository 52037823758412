import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Product } from '../entities/product';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { products } from '../mock/products';
import * as firebase from 'firebase';
import { ControllerService } from './controller.service';
import { TranslateService } from '@ngx-translate/core';



@Injectable({
  providedIn: 'root'
})
export class CardService {

  private _card: Map<number, number> = new Map<number, number>();
  private cookie: Map<Product, number>;
  private couponFlag = true;
  private coupon: any = {
    id: '',
    value: 0,
  };

  get couponId() {
    return this.coupon.id;
  }

  get couponValue() {
    return this.coupon.value;
  }

  get totalPrice() {
    let price = 0;
    this._card.forEach((value: number, key: number) => {
      price += value * products[key].price;
    });
    if (this.coupon !== undefined) {
      price -= price * this.coupon.value / 100;
    }
    return price;
  }

  get card(): Map<number, number> {
    const card = new Map<number, number>();
    this._card.forEach((value, key) => {
      card.set(key, value);
    });

    return card;
  }

  get totalCardItems(): number {
    let amount = 0;
    this._card.forEach((value: number, key: number) => {
      amount += value;
    });
    return amount;
  }

  get mwst() {
    return this.totalPrice * 19 / 119;
  }

  get itemList() {
    const items = [];
    this.card.forEach((value: number, key: number) => {
      if (value > 0 ) {
        items.push({
          name: products[key].name,
          quantity: value,
          unit_amount: {
            currency_code: 'EUR',
            value: products[key].price,
          },
        });
      }
    });
    return items;
  }

  constructor(
    private controller: ControllerService,
    private afs: AngularFirestore,
    private app: ControllerService,
    private translate: TranslateService
    ) {
      const cookie = controller.getCookie('card');

      if (cookie)
        this._card = new Map(JSON.parse(cookie));
      else
        products.forEach(prod => this._card.set(prod.id, 0));

    }

  addToCard(prod: Product, amount: number = 1): void {
    if (+this._card.get(prod.id) + +amount >= 0)
      this._card.set(prod.id, +this._card.get(prod.id) + +amount);
    else
    this._card.set(prod.id, 0);

    this.saveCookie();
  }

  setCard(prod: Product, amount: number): void {
    this.translate.get('item.' + prod.id + '.description').subscribe((val: string) => prod.description = val);
    this.translate.get('item.' + prod.id + '.name').subscribe((val: string) => prod.name = val);
    if (amount >= 0) {
      this._card.set(prod.id, amount);
    }
    this.saveCookie();
  }

  deleteFromCard(prod: Product): void {
    this._card.set(prod.id, 0);
    this.saveCookie();
  }

  resetCard() {
    this._card.forEach((value: number, key: number) => {
      this._card.set(key, 0);
    });
    this.saveCookie();
  }

  totalPriceOfProduct(prod: Product): number {
    return prod.price * this.card.get(prod.id);
  }

  async getCoupon(code: string) {
    if (this.couponFlag) {
      this.afs.collection('coupons').doc(code).get()
        .toPromise()
        .then(doc => {
          if (!doc.exists) {
            return null;
          }

          if ((doc.data() as any).disabled) {
            return null;
          }

          this.coupon = doc.data();
        });

      this.couponFlag = false;
      await this.app.delay(1000);
      this.couponFlag = true;
    }
  }

  saveCookie() {
    const date = new Date();
    this.controller.saveCookie('card', JSON.stringify(Array.from(this._card.entries())));
  }

  deleteCookies() {
    this.controller.removeCookie('card');
  }
}
