import { Component, OnInit, ViewChildren, QueryList, ElementRef, Renderer2, Sanitizer } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ControllerService } from '../../service/controller.service';
import { ContactService } from 'src/app/service/contact.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  @ViewChildren('tab') tabs: QueryList<ElementRef>;
  @ViewChildren('input') inputs: QueryList<ElementRef>;

  _iMail: string;
  _iPhone: string = '';
  iName: string;
  iAddress: string = '';
  iMsg: string;
  iSubject: string;
  tabHeight: number;
  titleImage = new Image();

  data = {
    name: 'Michael Jordan',
    bio: 'Former baseball player',
    image: 'avatar.png'
  };

  keypoints = [
    '<b>Nähr- und Sauerstoffzufuhr</b> der Haut verbessern und Alterserscheinungen bekämpfen',
    '<b>Attraktive Fülle</b> in angesprochenen Gesichtspartien durch Aufbau von Muskelgewebe (Anti-Falten)',
    '<b>Bessere Durchblutung</b> der Haut durch Aktivierung der umliegenden Muskeln',
    '<b>Natürliches Anti-Aging</b> Konzept, ohne Einsatz von Chemikalien',
    '<b>Besseres Gespür</b> für mimische Muskulatur und Lächeln',
    '<b>Made in Germany</b>',
  ];

  get screen() {
    if (this.controller.screenRes > 1) {
      return '-mb';
    }
    return '';
  }

  set iMail(mail: string) {
    if (this.emailFormControl.valid) {
      this._iMail = mail;
    }
  }

  set iPhone(nr: string) {
    if (this.phoneFormControl.valid) {
      this._iPhone = nr;
    }
  }

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  phoneFormControl = new FormControl('', [
    Validators.pattern('[+0-9 ]+'),
  ]);

  nameFormControl = new FormControl('', [
    Validators.required,
  ]);

  subjectFormControl = new FormControl('', [
    Validators.required,
  ]);

  msgFormControl = new FormControl('', [
    Validators.required,
  ]);

  constructor(
    public readonly controller: ControllerService,
    private readonly contactService: ContactService,
    private readonly _snackbar: MatSnackBar,
    private readonly renderer: Renderer2,
    private readonly title: Title,
    private readonly translate: TranslateService,
  ) {
    this.titleImage.src = '/assets/utrainer_above.jpg';
  }

  ngOnInit() {
    this.translate.get("titles.home").subscribe(title => this.title.setTitle(title));
  }

  resetInput() {
    this.inputs.forEach(i => {
      i.nativeElement.value = '';
    });
  }

  adjustTabHeight() {
    let height = 0;

    this.tabs.forEach(tab => {
      if (tab.nativeElement.offsetHeight > height) {
        height = tab.nativeElement.offsetHeight;
      } else {
        this.renderer.setStyle(tab.nativeElement, 'height', height);
      }
    });
    this.tabHeight = height;
  }

  sendMessage() {
    if (
      this.emailFormControl.valid &&
      this.phoneFormControl.valid &&
      this.nameFormControl.valid &&
      this.subjectFormControl.valid &&
      this.msgFormControl.valid
    ) {
      const msg = {
        name: this.iName,
        mail: this._iMail,
        subject: this.iSubject,
        msg: this.iMsg,
        phone: this._iPhone,
        address: this.iAddress
      };

      this.contactService.sendMessage(msg);
      this.openSnackBar('Ihre Nachricht wurde gesendet', 'Schließen');
    }
  }

  openSnackBar(message: string, action: string) {
      this._snackbar.open(message, action, {
        duration: 2000,
        panelClass: ['.successful-contact'],
      });
  }
}
